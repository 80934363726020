// import AlContext from './components/autorizatii-context';
import React from 'react';
// import BurgerMenu from './components/burger';
import Enter from './components/enter';
import './styles/enter.css';
// import NumeleDvs from './components/numele-dvs';

class App extends React.Component{

  render() {
    return(
      <React.Fragment>
        <Enter/>
      </React.Fragment>
    )
  }
}

export default App;